import React from 'react';

import Breadcrumbs from '../../../components/Breadcrumbs';
import Link from '../../../components/Link';
import Seo from '../../../components/Seo';

const RodynnoPobutovaLirykaPage = ({ pageContext }) => {
  return (
    <>
      <Seo
        title="Родинно-побутова лірика"
        description="Родинно-побутові пісні — це ліричні поетично-музичні твори, в яких відбиті почуття, переживання, думки людини, пов'язані з її особистим життям, подіями в сім'ї, родинними стосунками."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <Breadcrumbs
            crumbs={pageContext.breadcrumb.crumbs}
          />
          <h1 className="typo-h1 lowercase mt-4">
            Родинно-побутова лірика
          </h1>
          <p className="typo-body mt-4 max-w-3xl">
            Родинно-побутові пісні — це ліричні поетично-музичні твори, в яких відбиті почуття, переживання, думки людини, пов'язані з її особистим життям, подіями в сім'ї, родинними стосунками.
          </p>
        </div>
      </div>
      <div className="container-lg mt-10 md:mt-24">
        <h2 className="typo-h2">
          Жанри родинно-побутової лірики
        </h2>
        <ul className="mt-10">
          <li className="typo-h4 mt-4">
            <Link to="/pisni/rodynno-pobutova-liryka/pisni-pro-kokhannia/">
              Пісні про кохання
            </Link>
          </li>
          <li className="typo-h4 mt-4">
            <Link to="/pisni/rodynno-pobutova-liryka/pisni-pro-rodynne-zhyttia/">
              Пісні про родинне життя
            </Link>
          </li>
          <li className="typo-h4 mt-4">
            <Link to="/pisni/rodynno-pobutova-liryka/kolyskovi-pisni/">
              Колискові пісні
            </Link>
          </li>
          <li className="typo-h4 mt-4">
            <Link to="/pisni/rodynno-pobutova-liryka/zhartivlyvi-pisni/">
              Жартівливі пісні
            </Link>
          </li>
        </ul>
      </div>
      <div className="container-lg py-10 md:py-24">
        <h2 className="typo-h2">
          Опис жанру родинно-побутової лірики
        </h2>
        <p className="typo-body mt-4">
          Родинно-побутові пісні — це ліричні поетично-музичні твори, в яких відбиті почуття, переживання, думки людини,
          пов´язані з її особистим життям, подіями в сім´ї, родинними стосунками.
        </p>
        <p className="typo-body mt-4">
          Незважаючи на події, які описані в поетичній ліриці, вона завжди проймає відкритістю та ніжністю. Її авторам
          притаманне чітко-образне мислення, вони описують в своїх творах те, що зачіпає їхні душі, що породжує радісні
          емоції чи навпаки сумні; в ліричних піснях характерно виражаються характери людей: задирлива чи смиренна,
          непіддатлива чи покірна, лайлива чи ніжна. Через відчуття однієї особи передається характер всього народу.
          “Мені завжди здається, — писала Леся Українка, — що коли де можна побачити вдачу народу, то се скоріше в
          ліричних піснях та в коломийках”. Про коломийки поговоримо окремо, а тут відзначимо тільки, що тематика і
          настроєвий діапазон родинно-побутової лірики дуже обширний, а співзвучність із загальнолюдськими переживаннями
          забезпечила їй народну любов і незгасаючу популярність.
        </p>
        <p className="typo-body mt-4">
          Перші публікації української народно-пісенної лірики належить до XVIII ст. (у збірниках пісень М. Чулкова,
          В. Трутовського, І. Прача та ін). Починаючи з XIX ст. родинно побутова лірика публікується регулярно. До
          найбільш відомих досліджень цього жанру належать: дисертація О. Бодянського “Про народну поезію слов’янських
          племен”, праці І. Франка, О. Потебні, М. Лисенка, К. Квітки, Д. Яворницького, А. Кінька, О. Дея.
        </p>
      </div>
    </>
  );
};

export default RodynnoPobutovaLirykaPage;
